import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@core';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

const WrapperWithClickHandler = styled.button`
  /* reset properties */
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  margin: 0;
  display: flex;
`;

const Tabs = ({
  headerWrapper: HeaderWrapper = ({ children }) => (
    <Box width="100%" display="flex">
      {children}
    </Box>
  ),
  tabsData = [],
  labelComponent: LabelComponent,
  contentComponent: ContentComponent,
  onOpen,
  externalOpenedTab,
  openExternal,
}) => {
  const [openItem, setOpenItem] = useState(externalOpenedTab || 0);

  const handleTabChange = useCallback(
    (index) => {
      if (index !== openItem) {
        trackCustomGA4Event({
          eventName: GA4_EVENTS.tabClick(tabsData[index].labelProps?.label?.toLowerCase()),
        });
        setOpenItem(index);

        if (onOpen) {
          onOpen(index);
        }
      }
    },
    [onOpen, openItem, tabsData]
  );

  const handleOpen = useCallback(
    (index) => {
      if (openExternal) {
        openExternal(index);
      } else {
        handleTabChange(index);
      }
    },
    [handleTabChange, openExternal]
  );

  useEffect(() => {
    if (externalOpenedTab !== undefined) {
      const nextItem =
        externalOpenedTab > tabsData.length - 1
          ? tabsData.length - 1
          : externalOpenedTab < 0
          ? 0
          : externalOpenedTab;
      handleTabChange(nextItem);
    }
  }, [externalOpenedTab, handleTabChange, tabsData.length]);

  return (
    <Grid.Container>
      <Grid.Row>
        <HeaderWrapper>
          {tabsData.map(({ labelProps }, index) => (
            <WrapperWithClickHandler key={index} onClick={() => handleOpen(index)}>
              <LabelComponent isOpen={openItem === index} {...labelProps} />
            </WrapperWithClickHandler>
          ))}
        </HeaderWrapper>
      </Grid.Row>
      <Grid.Row>
        {tabsData.map(
          ({ contentProps }, index) =>
            openItem === index && (
              <ContentComponent key={index} isOpen={openItem === index} {...contentProps} />
            )
        )}
      </Grid.Row>
    </Grid.Container>
  );
};

export default Tabs;
